import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import NotFound from '@/views/NotFound.vue'
import ServerError from '@/views/ServerError.vue'
import Homepage from '@/views/Homepage.vue'
import store from '@/store'
import { getSubdomain } from '@/helpers/utility.js'

import membersRoutes from '@/router/modules/members.js'
import adminRoutes from '@/router/modules/admin/admin.js'
import userRoutes from '@/router/modules/user.js'
import recordsRoutes from '@/router/modules/records.js'
import aircraftRoutes from '@/router/modules/admin/aircraft.js'
import techlogRoutes from '@/router/modules/techlog.js'
import flightsRoutes from '@/router/modules/flights.js'
import invoicingRoutes from '@/router/modules/invoicing.js'
import vouchersRoutes from '@/router/modules/vouchers.js'
import noticesRoutes from '@/router/modules/notices.js'
import maintenanceRoutes from '@/router/modules/maintenance.js'
import bookingsRoutes from '@/router/modules/bookings.js'
import semetRoutes from '@/router/modules/semet.js'
import publicRoutes from '@/router/modules/public.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    beforeEnter: (to, from, next) => {
      let subdomain = getSubdomain()
      if (subdomain && store.getters['user/loggedIn']) {
        // Get the first navbar link that the user has permission to access
        let firstRoute = store.getters['nav/authorisedLinks'][0].route
        next({ name: firstRoute })
      } else if (subdomain && from.name == 'Login') {
        // If already on the login page don't redirect
        next(false)
        NProgress.done()
      } else if (subdomain) {
        // If a subdomain is specified but not logged in then go straight to login page
        next({ name: 'Login' })
      } else {
        next()
      }
    },
    component: Homepage,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { permissions: [] },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    beforeEnter(to, from, next) {
      store.dispatch('app/loadDashboard').then(() => next())
    },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/500',
    name: '500',
    component: ServerError,
  },
  {
    path: '/qrh',
    name: 'QRH',
    component: ServerError,
    beforeEnter(to, from, next) {
      next(from)
    },
  },
  {
    path: '*',
    redirect: { name: '404' },
  },
  ...membersRoutes,
  ...adminRoutes,
  ...userRoutes,
  ...recordsRoutes,
  ...aircraftRoutes,
  ...techlogRoutes,
  ...flightsRoutes,
  ...invoicingRoutes,
  ...vouchersRoutes,
  ...noticesRoutes,
  ...maintenanceRoutes,
  ...bookingsRoutes,
  ...semetRoutes,
  ...publicRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Define public views - require login for everything else
const public_views = [
  '404',
  '500',
  'Login',
  'Index',
  'Logout',
  'PasswordCreate',
  'PasswordReset',
  'TokenExpired',
  'VoucherRedeem',
  'AEX',
]

// Add all names from publicRoutes here
for (let i = 0; i < publicRoutes.length; i++) {
  public_views.push(publicRoutes[i].name)
}

router.beforeEach((to, from, next) => {
  // Check to see if any unsaved changes
  if (store.getters['app/editable'].length) {
    // If there are then display the dialog
    store.dispatch('app/unsavedDialog', to)
    return next(false)
  }

  NProgress.start()
  store.dispatch('app/fetchBranding').then(() => {
    // If listed as a public view then go straight to it
    // This will stop a loop condition for example if the user auth
    // api call returns 500, we don't want to keep trying and spam the server!
    if (['404', '500'].includes(to.name)) {
      next()
    }
    store.dispatch('user/syncAuth').then(() => {
      store.dispatch('app/loadConfig').then(() => {
        store.dispatch('user/syncBase')
        const user = store.getters['user/user']
        const permissions = store.getters['user/permissions']
        // For each match route check..
        to.matched.some((record) => {
          if (public_views.includes(record.name)) {
            // If listed as a public view then go straight to it
            next()
          } else {
            if (!user) {
              // No user object and trying a protected route then redirect to Login
              next({ name: 'Login' })
            } else if (!getSubdomain()) {
              // Trying to access protected route without subdomain
              next({ name: 'Index' })
            } else {
              // Permission check
              if (record.meta.persmissions) {
                record.meta.permissions.forEach((permission) => {
                  if (!permissions.includes(permission)) {
                    store.dispatch('user/unauthorisedLogout')
                  }
                })
              }
              next()
            }
          }
        })
      })
    })
  })
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

// Prevent duplicate navigation errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort).catch((err) => {
    console.log(err)
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

export default router
