<template>
  <v-app>
    <!-- Same colour as nav bar to prevent white bar showing when scroll bounce (pull down) -->
    <div class="d-flex flex-grow-1">
      <NavBar :navDrawer="navDrawer" @input="setNavBar" />
      <AppBar @drawer-toggle="navDrawer = !navDrawer" />
      <v-main class="no-double-tap background">
        <v-system-bar v-if="connectionWarning" class="connection-loss-bar">
          <v-container class="container-restrict">
            <v-row>
              <v-col class="d-flex align-center">
                <v-icon color="white" size="20">mdi-alert</v-icon>
                Connection loss detected - realtime updates may be disabled.
                Please refresh the page.</v-col
              >
              <v-col cols="auto" class="d-flex align-center">
                <v-icon size="30" color="white" @click="reload"
                  >mdi-refresh</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-system-bar>
        <v-container class="container-app">
          <router-view />
          <NotificationContainer :navDrawer="navDrawer" />
          <DownloadBar v-if="activeDownloads" />
          <BaseUnsavedChanges :display="unsavedDialog" />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
// import AppBar from '@/components/app/AppBar.vue'
import NavBar from '@/components/app/NavBar.vue'
import AppBar from '@/components/app/AppBar.vue'
import DownloadBar from '@/components/app/DownloadBar.vue'
import NotificationContainer from '@/components/app/NotificationContainer.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: { NavBar, NotificationContainer, AppBar, DownloadBar },
  data() {
    return {
      navDrawer: true,
    }
  },
  mounted() {
    // Make the top-background element visible (index.html)
    // setTimeout(() => {
    //   document.getElementById('top-background').style.display = 'block'
    // }, 1000)
  },
  methods: {
    setNavBar(value) {
      this.navDrawer = value
    },
    reload() {
      window.location.reload()
    },
  },
  computed: {
    ...mapGetters('app', ['unsavedDialog', 'connectionWarning']),
    ...mapGetters('user', ['loggedIn']),
    ...mapGetters('downloads', ['activeDownloads']),
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

///////////////////////////////////////////
// App Styling
///////////////////////////////////////////

/* Prevent the main application section from riding up into the navbar
when only a small amount of content is present. Issue is due to v-application-wrap
having a min-height of 100vh */

.v-application--wrap {
  min-height: auto;
  height: 100%;
}

.v-main {
  height: 100vh;
}
// Since we have now reduced the main content area,
// ensure the html background color matches the app background
html {
  background-color: var(--v-background-base);
}

// On mobile make the nav bar absolute so we get more screen space
// Set to same pixels as the breakpoint for hiding the nav bar
@media screen and (max-width: 1280px) {
  .v-app-bar {
    position: absolute !important;
  }
}

// Force on desktop as well to make more logical ux
.v-app-bar {
  position: absolute !important;
}

// Hide app bars when printing
@media print {
  .v-navigation-drawer {
    display: none;
  }
  .v-app-bar {
    display: none;
  }
  .v-main {
    padding: 0 !important;
  }
}

// Colours for the card backgrounds
.theme--light.v-card,
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #fdfdfd !important;
}

// Overscroll behaviour
html {
  overscroll-behavior-x: none;
}

// System bar
.connection-loss-bar {
  background-color: #c82c2c !important;
  color: white !important;
  min-height: 50px;
  height: auto !important;
}

/* Container max-widths */
.container {
  padding: 10px; // Reduce default padding to make more compact
  @media screen and (max-width: $sm) {
    padding: 7px; // Further reduce on mobile
  }
  @media (min-width: $md) {
    max-width: 1140px;
  }
  @media (min-width: $lg) {
    max-width: 1575px;
  }
  &-restrict {
    max-width: 1140px !important;
  }
  &-tabs {
    padding-top: 0 !important;
    margin-bottom: 8px;
  }
  &--fluid {
    max-width: 100% !important;
  }
  /* Centered container for login card etc */
  &-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 600px) {
      justify-content: start;
      margin-top: 10vh;
    }
  }
  &-app {
    @media screen and (max-width: $sm) {
      // Further reduce padding on the app container on mobile
      padding: 0 !important;
    }
  }
}

/* On mobile when columns are stacked there is a lot of space between
the columns so reducing the gap with slim-cols which can be applied to the row
*/
.slim-cols [class*='col'] {
  padding-top: 9px;
  padding-bottom: 9px;
}

// Add this to dialog content-class
.dialog-center {
  @media (min-width: 1280px) {
    // Set to width of nav drawer so we can centre dialogs when it's displayed
    // in the main view section
    margin-left: 256px !important;
  }
}

#nprogress .spinner {
  display: none !important;
}

/* Fix to ensure cursor appears when input field is selected on ios */
.v-input__slot {
  transition: none !important;
}

/* Disable the iOs double tap which causes the screen to zoom in */
.no-double-tap {
  touch-action: manipulation;
}

///////////////////////////////////////////
// Typography
///////////////////////////////////////////

// Fonts

html,
input,
select,
textarea,
button,
optgroup {
  font-size: $font-size;
}

.card-title {
  font-size: 1.25rem !important;
  font-weight: 500;
}

.title {
  font-weight: 600 !important;
}

.heading {
  font-size: 1.25rem;
  font-weight: bold;
}

.error {
  &--text {
    &.v-input--is-disabled {
      input,
      label {
        color: $error;
        -webkit-text-fill-color: $error;
        font-weight: bold;
      }
    }
  }
}

///////////////////////////////////////////
// Helpers
///////////////////////////////////////////

.w-100 {
  width: 100%;
}

.height-100,
.h-100 {
  height: 100% !important;
}

///////////////////////////////////////////
// Inputs
///////////////////////////////////////////

.v-input {
  // Font sizing
  font-size: $font-size !important;
  .v-label {
    font-size: $font-size !important;
  }

  // Reduce colour of border on outlined inputs
  .v-input__slot fieldset {
    color: rgba(0, 0, 0, 0.4) !important;
  }

  &.v-text-field--outlined:not(.v-input--dense) {
    .v-input__control {
      // .v-input__slot {
      //   min-height: 35px;
      // }
      .v-label {
        // Centre the label when no text in the input
        // Needs this after we override the height in variables.scss
        top: inherit;
      }
    }

    // Englarge the label when it's minimimised to the top
    .v-label.v-label--active {
      font-size: 17px !important;
      background-color: white; // Needed since width doesn't set correctly
      padding-right: 2px; // As above
    }

    .v-label + input {
      // Help make text input better vertically aligned when the label
      // is present
      padding-top: 12px !important;
    }

    .v-select__selections {
      // Reduce padding around the select input when label is set to above
      padding: 0 !important;
    }

    label + .v-select__selections {
      // Same vertical alignment mix but for selects
      // When it follows the label then we are not using label-above
      padding: 5px 0 3px 0 !important;
    }
  }
  &.v-text-field--outlined.v-input--dense {
    .v-label {
      top: 8px;
    }
  }
  &.v-textarea {
    textarea {
      margin-bottom: 10px;
    }
  }
  &--checkbox {
    label {
      // Make the checkbox label more prominent
      color: rgba(0, 0, 0, 0.78) !important;
    }
    &.indent {
      margin-left: 9px;
    }
  }

  &--is-readonly {
    // Subtle border for readonly inputs
    &.v-text-field--outlined fieldset {
      border: 1px solid rgba(0, 0, 0, 0.05) !important;
    }
    // Remove dropdowns on selects
    .v-icon {
      display: none !important;
    }
  }

  &--is-disabled {
    .v-label--is-disabled {
      // Make the label more prominent when disabled. Too grey by default
      color: rgba(0, 0, 0, 0.6) !important;
    }

    // When disabled make the content the normal colour
    input,
    textarea {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87); /* Fix safari opacity */
      opacity: 1; /* Fix safari opacity */
      pointer-events: auto !important;
    }

    // Remove the border for outlined text fields
    &.v-text-field--outlined {
      fieldset {
        border-color: rgba(0, 0, 0, 0) !important;
      }
    }

    // Same for selects
    .v-select__selection--disabled {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    // Make checboxes slightly darker when disabled
    &.v-input--selection-controls:not(.v-input--indeterminate) {
      .v-icon.mdi {
        color: rgba(0, 0, 0, 0.35) !important;
      }
      .v-label {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }

    // Dont show hover effect on checkboxes
    &.v-input--checkbox input {
      pointer-events: none !important;
    }
    // Remove dropdown arrows on selects
    &.v-select i {
      display: none !important;
    }
  }
}

// Space between adjacent v-inputs
// When it follows a disabled input we can get away with less
// since there is no underlined part to the preceeding input
.v-input + .v-input,
.v-input + .date-input {
  margin-top: 10px;
}
.v-input.v-input--is-disabled + .v-input {
  margin-top: 10px !important;
}
// Outlined inputs need more space since the label is above the input
.v-input + .v-input.v-text-field--outlined {
  margin-top: 25px;
}
// If we have input in error state then less space since the error has
// padding anyway
.v-input.error--text + .v-input.v-text-field--outlined {
  margin-top: 10px;
}

.slim-checkbox.v-input--checkbox {
  margin-top: 0;
}

// Icon position
// .v-text-field--outlined .v-input__append-inner,
// .v-text-field--outlined .v-input__append-outer,
// .v-text-field--outlined .v-input__prepend-outer {
//   margin-top: 10px !important;
// }

// Lists
.v-list {
  .v-list-item {
    min-height: 40px;
  }
  &.v-list--dense {
    .v-list-item__title {
      font-size: 0.9rem !important;
    }
  }
}

///////////////////////////////////////////
// Buttons
///////////////////////////////////////////

/* Button styles */
.v-btn:not(.v-btn--round).v-size--large {
  padding: 0 30px;
}

.pointer {
  cursor: pointer;
}

.text--link {
  color: #0b1e70;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.v-btn.v-btn--disabled .v-icon.mdi {
  color: rgba(0, 0, 0, 0.46) !important;
}

///////////////////////////////////////////
// Tables
///////////////////////////////////////////

.table-reduce-indent {
  table tr > td {
    padding-left: 0px !important; // Reduce padding on first td
  }
  table tr > th {
    padding-left: 2px !important; // Reduce padding on first header item
  }
}

.v-data-table.theme--light {
  background-color: #fdfdfd !important;
  line-height: 1.4;

  .v-data-table__wrapper {
    td,
    th {
      height: 40px; // Do not make important otherwise breaks other css
      padding: 2px 12px !important; /* Reduce line height on data table */
    }
  }
  // Reduce hover effect on data table
  tbody tr:hover:not(.no-hover) {
    background-color: #f8f8f8 !important;
  }
  // Disable hover effect for header row
  thead tr:hover {
    background-color: transparent !important;
  }
  &.clickable:hover {
    cursor: pointer;
  }
  .v-input {
    font-size: 0.933rem !important;
    margin-top: 0; /* Remove top paddng for inputs in table */
  }
  .v-label {
    font-size: 0.933rem !important;
  }

  /* Expanded table custom background */
  &.expanded-table {
    .v-data-table__expanded__content {
      box-shadow: none !important;
      background-color: #d3d3d31f;
      border-bottom: 1px solid lightgray !important;
      &:hover {
        background-color: #d3d3d31f !important;
      }
    }
  }

  &.no-hover {
    tbody tr:hover {
      background-color: transparent;
    }
  }

  &.v-data-table--mobile {
    .v-data-table__wrapper {
      /*Reduce white space between header and table on mobile */
      .v-data-table-header.v-data-table-header-mobile tr th {
        height: 22px !important;
      }

      table {
        border-collapse: collapse;

        tbody {
          /*Reduce padding on mobile data table rows for more compact fit */
          td.v-data-table__mobile-row {
            padding: 5px 1px !important;
            height: auto !important;
            min-height: auto;
          }
          tr.v-data-table__mobile-table-row {
            padding: 5px 0 !important;
          }

          // Put border between rows at bottom of row rather
          // than last td for improved visual spacing

          tr:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
          }
          td {
            border: none !important;
          }

          // Custom mobile flex tr
          .td-mobile {
            display: block;
            height: auto !important;
            padding: 5px 0px !important;
          }
        }
      }
    }
  }
  /* Ensure data table footer fits on one line in mobile*/
  @media screen and (max-width: $sm) {
    .v-data-footer__pagination {
      margin-left: 0 !important;
      margin-right: auto !important;
    }
    .v-data-footer__select {
      margin-right: 0px !important;
      justify-content: start !important;
      flex-basis: 100% !important;
    }
    .v-data-footer__select .v-select {
      margin: 10px 0 13px 5px !important;
    }
    .v-data-footer__select .v-input__append-inner {
      padding-left: 0 !important;
    }
    .v-data-footer__icons-after .v-btn,
    .v-data-footer__icons-before .v-btn {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

// Make field inputs within tables extra dense
.v-data-table .v-text-field--outlined {
  &.v-input {
    // Give vertical padding around the input
    margin: 5px 0 !important;
  }
  .v-input__slot {
    min-height: 32px !important;
    .v-label--active {
      // Correct label alignment when minimised to the top (required since
      // force reducing height of input box)
      transform: translateY(-14px) scale(0.75);
    }
  }
  // Can be further reduced with the .super-dense class
  &.super-dense {
    .v-input__slot {
      min-height: 32px !important;
    }
  }
  .v-select.v-text-field--outlined:not(.v-text-field--single-line)
    .v-select__selections {
    padding: 0 !important;
  }
  .v-input__append-inner {
    margin-top: 4px !important;
  }

  // When outlined inputs are disabled shift the input left to make up for
  // the missing border
  .v-input--is-disabled.v-text-field--outlined {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: -12px !important;
  }
}

// Kept for non data tables
.no-hover tr:hover,
.no-hover:hover {
  background-color: transparent !important;
}

////////////////////
// Page Headers
/////////////////////

.page-title {
  font-size: 1.7rem;
  letter-spacing: 0.0073529412em;
  font-weight: 300;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 1.5rem;
  }
}

.page-previous {
  margin-left: -10px;
  cursor: pointer;
  &:focus::after {
    opacity: 0 !important;
  }
}

.page-next {
  cursor: pointer;
  &:focus::after {
    opacity: 0 !important;
  }
}

//////////////////
// Bullet List
//////////////////

.list-links li {
  text-align: left;
  list-style-type: disc;
}
.list-links li:not([disabled]) {
  cursor: pointer;
}
.list-links li:not([disabled]):hover {
  color: 'primary';
  text-decoration: underline;
}
</style>
