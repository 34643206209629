export const namespaced = true

export const state = {
  staffLinks: [
    {
      title: 'Dashboard',
      icon: 'mdi-monitor-dashboard',
      route: 'Dashboard',
      permissions: ['tenant_dev', 'tenant_dashboard'],
      linkTag: 'dashboard',
    },
    {
      title: 'Bookings',
      icon: 'mdi-calendar',
      route: 'Bookings',
      permissions: ['booking_view'],
      linkTag: 'bookings',
      tenant: ['aex', 'flightcloud'],
    },
    {
      title: 'Customers',
      icon: 'mdi-account-multiple',
      route: 'Members',
      permissions: ['user_all'],
      linkTag: 'members',
    },
    {
      title: 'Techlogs',
      icon: 'mdi-book',
      route: 'TechlogHome',
      permissions: ['tenant_dev', 'techlog_view'],
      linkTag: 'techlog',
    },
    {
      title: 'Flights',
      icon: 'mdi-airplane',
      route: 'Flights',
      permissions: ['tenant_dev', 'flight_all'],
      linkTag: 'techlog',
    },
    {
      title: 'Invoicing',
      icon: 'mdi-cash-register',
      route: 'Invoicing',
      permissions: ['tenant_dev', 'invoice_all'],
      linkTag: 'invoicing',
    },
    {
      title: 'Vouchers',
      icon: 'mdi-tag',
      route: 'Vouchers',
      permissions: ['tenant_dev', 'voucher_all'],
      linkTag: 'vouchers',
    },
    {
      title: 'Student Records',
      icon: 'mdi-school',
      route: 'StudentRecords',
      permissions: ['record_all'],
      linkTag: 'records',
    },
    // {
    //   title: 'Timesheet',
    //   icon: 'mdi-file-clock',
    //   route: 'Timesheet',
    //   permissions: ['tenant_dev'],
    //   linkTag: 'techlog',
    // },
    {
      title: 'Notices',
      icon: 'mdi-file-document-multiple',
      route: 'NoticeList',
      permissions: ['tenant_dev', 'noticeack_add'],
      linkTag: 'notices',
      counter: 'outstandingNotices',
    },
    {
      title: 'Maintenance',
      icon: 'mdi-wrench',
      route: 'Maintenance',
      permissions: ['tenant_mx'],
      tenant: ['aex', 'flightcloud'],
      linkTag: 'maintenance',
    },
    {
      title: 'Roster',
      icon: 'mdi-calendar-account',
      route: 'InstructorRoster',
      permissions: ['instructorprofile_roster'],
      linkTag: 'roster',
    },
    {
      title: 'Admin',
      icon: 'mdi-cog',
      route: 'Admin',
      permissions: ['tenant_admin'],
      linkTag: 'admin',
    },
    // {
    //   title: 'AEX',
    //   icon: 'mdi-desktop-classic',
    //   route: 'AEX',
    //   permissions: ['flight_all'],
    //   linkTag: 'aex',
    //   tenant: ['semet'],
    // },
    {
      title: 'Vouchers',
      icon: 'mdi-card-text',
      route: 'VoucherGenerate',
      permissions: ['semet_dev'],
      linkTag: 'vouchers',
      tenant: ['semet'],
    },
    {
      title: 'ADV001',
      icon: 'mdi-wallet-giftcard',
      route: 'Adventure',
      permissions: ['semet_dev'],
      linkTag: 'adventure',
      tenant: ['semet'],
    },
  ],
  userLinks: [
    {
      title: 'Logbook',
      icon: 'mdi-airplane',
      route: 'MemberLogbook',
      permissions: ['tenant_dev'],
      linkTag: 'logbook',
    },
    {
      title: 'Student Records',
      icon: 'mdi-school',
      route: 'StudentIndex',
      linkTag: 'records',
      permissions: [],
    },
    {
      title: 'Notices [BETA]',
      icon: 'mdi-file-document-multiple',
      route: 'NoticeList',
      permissions: ['tenant_dev', 'noticeack_add'],
      linkTag: 'notices',
      counter: 'outstandingNotices',
    },
  ],
}

export const mutations = {}

export const actions = {}

export const getters = {
  authorisedLinks(state, getters, rootState, rootGetters) {
    // First filter to those that the user has permissions for
    let links = []
    if (rootGetters['user/staffMode']) {
      links = state.staffLinks.filter((link) => {
        return rootGetters['user/hasPerms'](link.permissions)
      })
    } else {
      links = state.userLinks.filter((link) => {
        return rootGetters['user/hasPerms'](link.permissions)
      })
    }
    // Then filter to those that the user has tenant permissions for
    links = links.filter((link) => {
      if (link.tenant) {
        return link.tenant.includes(rootGetters['app/tenant'])
      } else {
        return true
      }
    })
    return links
  },
}
