import store from '@/store'

const publicRoutes = [
  {
    path: '/checkout-success',
    name: 'StripeSuccess',
    component: () =>
      import(
        /* webpackChunkName: "stripe" */ '@/views/public/StripeSuccess.vue'
      ),
  },
  {
    path: '/voucher-shop',
    name: 'VoucherBuy',
    meta: { home: 'VoucherLogin' },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '@/views/public/VoucherBuy'),
    beforeEnter(to, from, next) {
      store.dispatch('user/tempClearUser')
      store.dispatch('voucherPortal/clearStore')
      next()
    },
  },
  {
    path: '/book',
    name: 'VoucherLogin',
    meta: { home: 'VoucherLogin' },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '@/views/public/VoucherLogin'),
    beforeEnter(to, from, next) {
      store.dispatch('user/tempClearUser')
      next()
      store.dispatch('voucherPortal/clearStore')
    },
  },
  {
    path: '/voucher-portal',
    name: 'VoucherPortal',
    meta: { home: 'VoucherLogin' },
    component: () =>
      import(/* webpackChunkName: "voucher" */ '@/views/public/VoucherPortal'),
    beforeEnter(to, from, next) {
      // If voucher in store then proceed
      if (store.getters['voucherPortal/voucher']) {
        next()
      } else if (to.query.voucher) {
        store
          .dispatch('voucherPortal/validateJwt', to.query.voucher)
          .then((res) => {
            if (res) {
              next()
            }
          })
          .catch(() => {
            store.dispatch('notification/add', {
              type: 'error',
              message: 'Invalid voucher token',
            })
            next({ name: 'VoucherLogin' })
          })
      } else {
        next({ name: 'VoucherLogin' })
      }
    },
  },
]

export default publicRoutes
