import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import vuetify from './plugins/vuetify'
import appConfig from '@/plugins/config'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField
import * as Sentry from '@sentry/vue'

/* Global Component Registration
https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
----------------------------------- */
const requireComponent = require.context(
  // The relative path of the components folder
  './components/global',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

/* ----------------------------------- */

// Register app config so its available via this.$appConfig
Vue.prototype.$appConfig = appConfig
Vue.prototype.$NProgress = NProgress

Vue.config.productionTip = false

// Global mixin to inject into every component
Vue.mixin({
  computed: {
    $user() {
      return store.getters['user/user']
    },
    $perm() {
      return store.getters['user/hasPerm']
    },
    $group() {
      return store.getters['user/hasGroup']
    },
    $base() {
      return store.getters['user/base']
    },
    $config() {
      return store.getters['app/tenantConfig']
    },
    $tenant() {
      return store.getters['app/tenant']
    },
    $liveUpdates() {
      if (process.env.NODE_ENV === 'development') {
        return true
      } else {
        return true
      }
    },
  },
  methods: {
    $editMode(tag) {
      return store.dispatch('app/editMode', tag)
    },
    $cancelEdit(tag) {
      return store.dispatch('app/cancelEdit', tag)
    },
    $editing(tag) {
      return this.$store.getters['app/isEditable'](tag)
    },
  },
})

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: null,
})

// Sentry error tracking

// Set a toggle to enable or disable Sentry error tracking in dev mode
const sentryDevEnabled = false

// Set the Sentry DSN
// Always enable Sentry in production. Conditional for development
let dsn =
  'https://31f70e55de22e1cdba26cbd5b6c29ac6@o4507956119142400.ingest.de.sentry.io/4507956121108560'
if (process.env.NODE_ENV === 'development' && !sentryDevEnabled) {
  dsn = ''
}

Sentry.init({
  Vue,
  dsn: dsn,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.captureConsoleIntegration({
      levels: ['warn', 'error', 'debug', 'assert'],
    }),
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  environment: process.env.NODE_ENV,
  beforeSend(event) {
    if (event.message) {
      // Supress Firebase console errors
      if (event.message.includes('@firebase/firestore')) {
        return null
      }
    }
    // Allow other warnings and errors to be sent
    return event
  },
})

// Store original console methods
const originalConsoleWarn = console.warn
const originalConsoleError = console.error

// Override both console.warn and console.error to intercept Firestore warnings/errors
const interceptConsole = (type, originalMethod) => {
  return function (message, ...args) {
    // Check for the Firestore transport error or warning
    if (
      typeof message === 'string' &&
      message.includes('@firebase/firestore')
    ) {
      store.dispatch('app/setConnectionWarning', true)
    }

    // Call the original console method
    originalMethod.apply(console, [message, ...args])
  }
}

// Override console.warn and console.error
console.warn = interceptConsole('warning', originalConsoleWarn)
console.error = interceptConsole('error', originalConsoleError)

// Listener for setting warning state when offline
const handleOffline = () => {
  store.dispatch('app/setConnectionWarning', true)
}
window.addEventListener('offline', handleOffline)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeDestroy() {
    // Remove the listener when the app is destroyed
    window.removeEventListener('offline', handleOffline)
  },
}).$mount('#app')
