import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'
import { durationToSeconds } from '@/helpers/utility'

export const namespaced = true

export const state = {
  endpoint: '/records',
  list: {
    fields:
      '{id, date, instructor{full_name}, instructor_signed, student_signed}',
    default: null,
    filtered: null,
    fetching: false,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      course_subscription: {
        label: 'Course Subscription',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields:
      '{id, student, instructor, date, course_subscription{id}, record_items, \
      record_lessons, manual_flights, next_lesson, instructor_signed, \
      student_signed, comments, record_type, attachments, \
      flights}',
    data: null,
  },
  courseData: null,
  outstandingRecords: [],
  instructorDrafts: [],
}

export const mutations = {
  SET_COURSE_DATA(state, { data }) {
    state.courseData = data
  },
  SET_OUTSTANDING_RECORDS(state, { data }) {
    state.outstandingRecords = data
  },
  ...apiMutations,
}

export const actions = {
  fetchCourseData({ commit }, courseSubscriptionID) {
    return api
      .get(`course_subscriptions/${courseSubscriptionID}/`)
      .then((response) => {
        commit('SET_COURSE_DATA', { data: response.data })
      })
  },
  loadCourseData({ state, dispatch }, { params }) {
    let courseSubscriptionID = params.id
    if (state.courseData && state.courseData.id == courseSubscriptionID) {
      return
    } else {
      return dispatch('fetchCourseData', courseSubscriptionID)
    }
  },
  clearCourseData({ commit }) {
    commit('SET_COURSE_DATA', { data: null })
  },
  fetchInstructorDrafts({ state, rootGetters }) {
    // Get the current user
    let user = rootGetters['user/user']
    return api
      .get(
        `/records/?instructor=${user.id}&instructor_signed__isnull=true&query={id, student{full_name}, date}`
      )
      .then((res) => (state.instructorDrafts = res.data.results))
  },
  fetchOutstandingRecords({ commit }) {
    let params = {
      limit: 999,
      record__isnull: true,
      returned_by__isnull: false,
      instructor__isnull: false,
      query: '{id, customer{id, full_name}, instructor{id, full_name}, date}',
    }
    return api.get(`flights/`, { params }).then((response) => {
      commit('SET_OUTSTANDING_RECORDS', { data: response.data.results })
    })
  },
  loadOutstandingRecords({ state, dispatch }) {
    if (state.outstandingRecords.length) {
      // Return straight away
      return
    } else {
      // Fetch the latest and return promise
      return dispatch('fetchOutstandingRecords')
    }
  },
  ...apiActions,
}

export const getters = {
  courseData(state) {
    if (state.courseData) {
      return state.courseData
    } else {
      return {}
    }
  },
  courseSummary(state) {
    if (state.courseData) {
      return state.courseData.summary
    } else {
      return {}
    }
  },
  courseStages(state) {
    let res = []
    let lessons = state.courseData.summary.lessons
    let exams = state.courseData.summary.exams

    for (let stage of state.courseData.summary.stages) {
      // For easy lookup store stages by key
      res.push(stage)

      // Default stage to completed
      stage.completed = true

      // For each of the requirements in the stage check to see if passed
      for (let req of stage.requirements) {
        // Checking whether ground training hours requirements are met
        if (req.lesson && req.lesson_hours) {
          let lesson = lessons.find((lesson) => lesson.id == req.lesson)
          // Get total hrs of ground training
          let total
          if (lesson.ground_training.length) {
            total = lesson.ground_training[0].cumulative_total
          } else {
            total = '00:00:00'
          }
          // Check to see if target is met
          if (durationToSeconds(total) >= durationToSeconds(req.lesson_hours)) {
            req.passed = true
          } else {
            req.passed = false
          }
        } else if (req.lesson) {
          // Checking if lesson has been completed
          let lesson = lessons.find((lesson) => lesson.id == req.lesson)
          lesson.completed ? (req.passed = true) : (req.passed = false)
        } else if (req.exam) {
          // Checking if exam has been completed
          let exam = exams.find((exam) => exam.id == req.exam)
          exam.passed ? (req.passed = true) : (req.passed = false)
        } else if (req.dual_hours) {
          // Checking total dual hours
          let achieved = durationToSeconds(state.courseData.summary.hours.dual)
          let target = durationToSeconds(req.dual_hours)
          achieved >= target ? (req.passed = true) : (req.passed = false)
        }

        // Once we've assessed the requirement can set stage passed false if appropriate
        !req.passed ? (stage.completed = false) : false
      }
    }
    return res
  },
  unlockedStages(state, getters) {
    let res = []
    // Get index of the first stage which is not completed
    let firstIncomplete = getters.courseStages.findIndex(
      (stage) => !stage.completed
    )

    if (firstIncomplete == -1) {
      // If no stages are incomplete then all stages are unlocked
      res = getters.courseStages.map((stage) => stage.id)
      return res
    } else {
      // We will unlock one stage higher than first incomplete one
      for (let [index, stage] of getters.courseStages.entries()) {
        if (index <= firstIncomplete) {
          res.push(stage.id)
        } else {
          return res
        }
      }
    }
  },
  outstandingRecords(state) {
    return state.outstandingRecords
  },
  instructorDrafts(state) {
    return state.instructorDrafts
  },
  ...apiGetters,
}
